<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Confirmation Begin Balance</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="red--text text-uppercase font-weight-bold">warning !</div>
            <div>
              This action will lock all journal last year and create new begin balance to this year
              <br />
              <br />
              <div>
                Please type <span class="font-weight-bold">GSPE-new Begin Balance</span> to confirm.
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined dense flat v-model="validation"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close">
          No
        </v-btn>
        <v-btn color="success" @click="confirm">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-confirm-begin-balance",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      validation: "",
    };
  },
  methods: {
    confirm() {
      if (this.validation === "GSPE-new Begin Balance") {
        this.$emit("save");
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
