<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menuDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-checkbox label="Buffer" v-model="params.buffer"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="download" class="mr-2">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
          <v-btn color="primary" @click="openConfirm" class="mr-2">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        hide-default-footer
        disable-pagination
        :search="filter"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="8" lg="8">
                <v-text-field
                  flat
                  dense
                  outlined
                  background-color="white"
                  v-model="filter"
                  placeholder="search"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.balance="{ item }">
          <span v-if="!params.buffer">{{ formatPrice(item.balance) }}</span>
          <v-text-field
            flat
            outlined
            dense
            type="number"
            persistent-hint
            :hint="formatPrice(item.balance)"
            background-color="white"
            v-model="item.balance"
            @change="onChangeBalance($event, item)"
          ></v-text-field>
        </template>
        <template v-slot:item.rate="{ item }">
          <span v-if="!params.buffer">{{ formatPrice(item.rate) }}</span>
          <v-text-field
            flat
            outlined
            dense
            type="number"
            persistent-hint
            :hint="formatPrice(item.rate)"
            background-color="white"
            v-model="item.rate"
            @change="onChangeRate($event, item)"
          ></v-text-field>
        </template>
        <template v-slot:item.balanceIdr="{ item }">
          <span v-if="!params.buffer">{{ formatPrice(item.balanceIdr) }}</span>
          <v-text-field
            flat
            outlined
            dense
            type="number"
            persistent-hint
            :hint="formatPrice(item.balanceIdr)"
            background-color="white"
            v-model="item.balanceIdr"
          ></v-text-field>
        </template>
      </v-data-table>
    </v-col>
    <dialog-confirm-begin-balance
      :dialog="dialog"
      @save="approved"
      @close="close"
    ></dialog-confirm-begin-balance>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";
import DialogConfirmBeginBalance from "./DialogConfirmBeginBalance.vue";
export default {
  name: "report-begin-balance",
  components: {
    DialogConfirmBeginBalance,
  },
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Pointer",
        value: "pointer",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Nature",
        value: "nature",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Balance",
        value: "balance",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Balance IDR",
        value: "balanceIdr",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    filter: "",
    items: [],
    date: moment()
      .startOf("year")
      .format("YYYY-MM-DD"),
    params: {
      year: 0,
      buffer: false,
    },
    dialog: false,
    menuDate: false,
  }),

  methods: {
    async search() {
      this.params.year = moment(this.date).year();
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/beginBalance", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async download() {
      this.params.year = moment(this.date).year();
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/beginBalanceExcel", this.params)
        .then(response => {
          fileDownload(response.data, "begin-balance.xlsx");
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    openConfirm() {
      if (this.items.length > 0) {
        this.dialog = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Mohon Untuk Search Terlebih Dahulu",
        });
      }
    },
    close() {
      this.dialog = false;
    },
    onChangeBalance(val, item) {
      item.balanceIdr = val * item.rate;
    },
    onChangeRate(val, item) {
      item.balanceIdr = item.balance * val;
    },
    async approved() {
      this.$store.commit("SET_LOADER", true);
      if (this.params.buffer) {
        await this.$store
          .dispatch("report/bufferBeginBalanceApproved", {
            data: this.items,
            year: moment(this.date).year(),
          })
          .then(() => {
            window.location.reload();
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        await this.$store
          .dispatch("report/beginBalanceApproved", moment(this.date).year())
          .then(() => {
            window.location.reload();
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
  },
};
</script>

<style></style>
